@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  flex: 1 1 100%;
  z-index: 1;
  margin-top: vars.$gutter;
}

.hasFilters {
  @media screen and (min-width: vars.$extra-large) {
    padding-top: 207px;
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    padding-top: 157px;
  }
}

.pageHeading {
  margin: 0 0 16px 0;
  color: colors.$secondary-text;

  &:first-letter {
    text-transform: capitalize;
  }

  &Mobile {
    font-size: inherit;
    margin:  0;

    > span {
      vertical-align: middle;
    }

    @media screen and (min-width: vars.$medium) {
      margin-left: 25px;
    }

    @media screen and (min-width: vars.$extra-large) {
      padding-top: 5px;
      margin-left: 0;
      text-align: right;
    }
  }

  @media screen and (min-width: vars.$extra-large) {
    margin-top: 0;
  }
  span span {
    font-stretch: condensed;
    font-weight: bold;
  }
}

.filtersBtnContainer {
  order: 1;

  b {
    margin-right: 5px;
  }

  p {
    margin-right: 5px;
  }

  @media screen and (min-width: vars.$medium) {
    margin-top: 0;
    order: 4;
  }

  @media screen and (min-width: vars.$extra-large) {
    display: none;
  }

  button {
    @media screen and (max-width: vars.$medium) {
      border-radius: 4px;
    }
  }
}

.filtersIcon {
  margin: 0 10px;
}

.statsAndViewOptionsContainer {
  margin-top: 30px;
}

.sortingOptions {
  align-items: center;
  display: none;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
    margin-top: 10px;
    order: 4;
  }
}

.sortingOption {
  align-items: center;
  color: colors.$secondary-text;
  display: flex;
  text-decoration: none;

  &:focus,
  &:hover {
    color: colors.$primary-color;
  }
}

.sortingOptionSelected {
  color: colors.$primary-color;
  font-stretch: condensed;
  font-weight: bold;
}

.sortingOptionDirectionIcon {
  margin-left: 5px;
}

.viewOptions {
  align-items: center;
  display: none;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: flex-end;
  list-style-type: none;
  margin: 15px 0 0;
  order: 3;
  padding: 0;

  &:before {
    content: '';
    background: colors.$grey;
    width: 1px;
    display: inline-block;
    height: 60%;
    margin-top: -5px;
    margin-right: 15px;
  }

  li:first-child {
    margin-right: 10px;
  }
  @media screen and (max-width: vars.$medium) {
    background: colors.$primary-color;
    margin: 0;
    border-radius: 4px;
    padding: 5px 15px 0 0;

    li:first-child {
      margin-right: 0;
    }
  }
  @media screen and (min-width: vars.$medium) {
    order: 4;
  }

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
    order: 5;
  }
}

.breadcrumbsWrapper {
  padding-bottom: vars.$nodeSpacing;
  border-bottom: 1px solid colors.$grey;
  
  @media screen and (max-width: vars.$medium) {
    gap: 0;
  }
}

.viewOption {
  color: colors.$secondary-text;
  text-decoration: none;

  &:focus,
  &:hover {
    color: colors.$primary-color;
  }
}

.viewOptionSelected {
  color: colors.$white;
  font-stretch: condensed;
  font-weight: bold;

  @media screen and (min-width: vars.$medium) {
    color: colors.$primary-color;
  }
}

.resultsCounter {
  align-items: center;
  margin: 15px 0 0;
  order: 2;

  @media screen and (min-width: vars.$medium) {
    margin-top: 30px;
    text-align: right;
  }

  @media screen and (min-width: vars.$extra-large) {
    margin-top: 0;
  }
}

.product {
  background: colors.$white;
  border-radius: 4px;
  flex: 0 0 100%;
  height: 217px;
  padding: 15px;
  position: relative;
}

.productWishlistBtn {
  background: none;
  border: none;
  color: colors.$secondary-text;
  position: absolute;
  right: 15px;
  top: 15px;

  &.productWishlistSelectedBtn {
    color: colors.$error;
  }
}

.divider {
  display: none;

  @media screen and (min-width: vars.$medium) {
    background: colors.$grey;
    display: block;
    height: 1px;
    margin: 15px 0;
    order: 3;
  }
}

.paginationAndSummaryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 30px 0;

  @media screen and (min-width: vars.$medium) {
    flex-direction: row;
    align-items: initial;
  }
  @media screen and (min-width: vars.$extra-large) {
    margin: 0;
  }
}

.summary {
  display: none;

  &:first-of-type {
    margin-right: 30px;
  }

  @media screen and (min-width: vars.$medium) {
    align-items: center;
    display: flex;
  }
}

.pagination {
  @media screen and (min-width: vars.$medium) {
    justify-content: right;
    margin-left: auto;
  }
}

@media screen and (min-width: vars.$extra-extra-large) {
  .productDetailsCard {
    grid-column-start: span 15;

    &-twoColumn {
      grid-column-start: span 12;
    }
  }
}

.viewOptionsColumns {
  @media screen and (min-width: vars.$medium) and (max-width: #{vars.$extra-extra-large - 1}) {
    .productDetailsCard {
      grid-column-start: span 12;
    }
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    .productDetailsCard {
      grid-column-start: span 12;
    }
  }
}

.mainColor {
  background-color: colors.$background;
}

.filters {
  display: none;

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
    flex-flow: column;
    padding-right: 15px;
    position: sticky;
    top: 155px;
    overflow-y: auto;
    z-index: 3;
    max-height: calc(100vh - 155px);
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    max-height: calc(100vh - 155px);
  }
}

nav.breadCrumbs {
  margin-top: 0;
}