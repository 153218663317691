@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  padding-bottom: calc(vars.$gutter * 2);
}

.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, 235px);
  gap: vars.$gutter;
  overflow: auto;
  grid-auto-flow: column;
  padding: 10px 0;
}

.product {
  width: 227px;
}

.slidersRoot {
  margin: 0;
  margin-top: -10px;
}

.slidesWrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;

  @media screen and (min-width: vars.$large) {
    gap: vars.$gutter;
  }
}

.productCardWrapper {
  padding-top: 10px;
}
