@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  padding-bottom: calc(vars.$gutter * 2);
  padding-top: vars.$nodeSpacing;

  header {
    margin-bottom: 8px;
  }
}

.intro {
  display: block;
  margin-bottom: vars.$nodeSpacing;
  font-stretch: condensed;
  color: colors.$secondary-text;
}

.brands {
  display: grid;
  gap: vars.$gutter;

  @media screen and (min-width: vars.$medium) {
    gap: vars.$nodeSpacing;
  }
}

.brand {
  border-radius: 3px;
  background: white;
  display: grid;
  grid-template-columns: calc(100% - 80px) 80px;
  align-items: center;
  text-decoration: none;
  font-stretch: condensed;
  height: 54px;
  overflow: hidden;
}

.brandName {
  padding: 0 vars.$nodeSpacing;
  align-items: center;
  display: flex;

  span {
    white-space: nowrap;
    width: calc(100% - 24px);
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.discount {
  position: relative;
  color: colors.$grey-default;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  &::before,
  &::after {
    content: ' ';
    display: inline-block;
    width: 12px;
    height: 12px;
    background: colors.$background;
    border-radius: 50%;
    position: absolute;
    left: -6px;
  }

  &::before {
    top: -6px;
  }

  &::after {
    bottom: -6px;
  }

  border-left: 2px dotted colors.$background;
}

.discountValue {
  color: colors.$dark-text;
  font-size: 16px;
}

.icon {
  margin-right: 10px;
}

.slidersRoot {
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

.slidesWrapper {
  gap: 16px;
  padding-top: 10px;
  flex-wrap: wrap !important;
  @media screen and (min-width: vars.$extra-large) {
    flex-wrap: nowrap !important;
  }

  article {
    width: 100%;
    @media screen and (min-width: vars.$medium) {
      width: calc(50% - 8px);
    }
    @media screen and (min-width: vars.$extra-large) {
      width: auto;
    }
  }
}
